import flush from 'just-flush'

/**
 * Construct a one level object of string values from a multilevel fields object with a data item guided by the enabled and propsMap properties.
 * e.g.
  {
    product_group: {
      enabled: true,
      propsMap: { id: 'product_group', account: 'account', tax: 'tax' },
      item: {
        id: 123456,
        account: 'revenue account',
        tax: 'tax account'
      }
    },
    staff: {
      enabled: true,
      propsMap: { id: 'staff' },
      item: { id: 85246 }
    },
    tags: {
      enabled: true,
      item: [
        { id: 1 },
        { id: 2 }
      ]
    }
  }

  returns:
  {
    product_group: 123456,
    account: 'revenue account',
    tax: 'tax account',
    staff: 85246,
    tags: [1, 2]
  }
 *
 * @param {Object} fields
 * @returns {Object}
 */
export function constructFields(fields = {}) {
  return Object.entries(fields)
    .filter(([key, field]) => field.enabled && !!field.item)
    .map(([key, field]) => [key, mapProps(field.item, field.propsMap)])
    .map(([key, field]) => [
      key,
      Array.isArray(field) ? field : { ...field, [key]: applyNull(field[key]) }
    ])
    .reduce(entriesMergeArraysReducer, [])
    .reduce(mergeObjectsReducer, {})
}

const entriesMergeArraysReducer = (acc = [], [key, value]) => {
  return Array.isArray(value) ? acc.concat({ [key]: value }) : acc.concat(value)
}

const mergeObjectsReducer = (acc = {}, curr = {}) => ({ ...acc, ...curr })

/**
 * Transform a data item object/array of objects by the provided props map
 * @param {Object|Array} item
 * @param {[Object]} propsMap
 * @returns {Object}
 */
export function mapProps(item, propsMap = {}) {
  // If item is an array, we'll assume for now that we only want to map the id and disregard a propsMap even if provided
  const _propsMap =
    propsMap && !Array.isArray(item) && Object.keys(propsMap).length
      ? propsMap
      : { id: 'id' }

  return Array.isArray(item)
    ? item.map((obj) => getProps(obj, _propsMap)).map(({ id }) => id)
    : getProps(item, _propsMap)
}

const getProps = (item, propsMap) => {
  return Object.entries(flush(item))
    .filter(entriesFilterByKey(Object.keys(propsMap)))
    .reduce(entriesPropsMapReducer(propsMap), {})
}

const entriesFilterByKey = (filterArray = []) => ([key = '']) =>
  filterArray.includes(key)

const entriesPropsMapReducer = (propsMap = {}) => (
  acc = {},
  [key = '', value = {}]
) => ({
  ...acc,
  [propsMap[key]]: value
})

export const applyNull = (value) => (value === 'null' ? null : value)
