<template>
  <th-page-wrapper
    class="staff-edit"
    :title="$t('pages.products.bulk_edit.title')"
    color="var(--secondary-color)"
    align-title="center"
    :scroll-content="false"
    @close-requested="$router.push({ name: 'products-manager' })"
  >
    <div v-loading="loading" class="flex flex-col items-center my-2">
      <div class="my-2 bg-blue-100 px-5 py-4 text-blue-500 rounded text-sm">
        <svgicon
          class="mr-3"
          :src="require('@/assets/icons/th-icon-help-transparent.svg')"
          :style="{ height: '18px', width: '18px', color: '#279FF6' }"
        />
        <span
          v-text="
            `${!!next ? '> ' : ''}${$t(
              'pages.products.bulk_edit.select_fields.info.message',
              i18nParams
            )}`
          "
        />
      </div>
      <div
        v-if="isExtraVariantsShown"
        class="my-2 px-5 py-4 bg-orange-100 text-orange-500 rounded text-sm"
      >
        <svgicon
          class="mr-3"
          :src="require('@/assets/icons/th-icon-help-transparent.svg')"
          :style="{ height: '18px', width: '18px', color: '#279FF6' }"
        />
        <span>
          {{
            $t('pages.products.bulk_edit.select_field.variants_added.message')
          }}
        </span>
      </div>
    </div>
    <select-change-fields
      v-if="step === 1"
      ref="fieldsChangeForm"
      v-model="fields"
      :resources="resources"
      :i18n-params="i18nParams"
    />
    <review-change-items
      v-if="step === 2"
      v-loading="pageLoading"
      :fields="fields"
      :products-to-change="productsList"
      :i18n-params="i18nParams"
      :resources="resources"
      @paginate="paginate"
    />
    <template #actions>
      <div class="h-full flex justify-end content-center items-center px-5">
        <el-button @click="handleCancel">
          {{ $t('common.interactions.buttons.cancel') }}
        </el-button>
        <el-button v-if="step === 2" type="primary" @click="handleBack">
          {{ $t('common.interactions.buttons.back') }}
        </el-button>
        <el-button
          v-if="step !== 2"
          :disabled="!anyFieldEnabled"
          type="primary"
          @click="handleNext"
        >
          {{ $t('common.interactions.buttons.review') }}
        </el-button>
        <el-button
          v-else-if="step === 2"
          type="primary"
          @click="bulkEditProducts"
        >
          {{ $t('common.interactions.buttons.save') }}
        </el-button>
      </div>
    </template>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import debounce from 'debounce'
import typeOf from 'just-typeof'
import SelectChangeFields from './select-change-fields'
import ReviewChangeItems from './review-change-items'
import { constructFields } from './utils.js'
import { useProductsStore } from '@/store/products'
import { storeToRefs } from 'pinia'
import flatten from 'just-flatten-it'

export default {
  components: {
    SelectChangeFields,
    ReviewChangeItems
  },
  setup() {
    const productsStore = useProductsStore()
    const {
      selectedItems,
      allSelected,
      persistedFilters: filters
    } = storeToRefs(productsStore)

    return { productsStore, selectedItems, allSelected, filters }
  },
  data() {
    return {
      step: 1,
      loading: false,
      resources: {},
      pageLoading: false,
      next: null,
      fields: {
        product_group: {
          enabled: false,
          propsMap: {
            id: 'product_group',
            account: 'account',
            takeaway_account: 'takeaway_account',
            tax: 'tax',
            takeaway_tax: 'takeaway_tax'
          },
          item: null
        },
        tags: {
          enabled: false,
          item: null
        },
        suppliers: {
          enabled: false,
          item: []
        },
        tax: {
          enabled: false,
          propsMap: { id: 'tax' },
          item: null
        },
        takeaway_tax: {
          enabled: false,
          propsMap: { id: 'takeaway_tax' },
          item: null
        },
        account: {
          enabled: false,
          propsMap: { id: 'account' },
          item: null
        },
        takeaway_account: {
          enabled: false,
          propsMap: { id: 'takeaway_account' },
          item: null
        },
        discountable: {
          enabled: false,
          propsMap: { enabled: 'discountable' },
          item: { enabled: false }
        },
        request_input: {
          enabled: false,
          propsMap: { enabled: 'request_input' },
          item: { enabled: false }
        },
        allow_is_free: {
          enabled: false,
          propsMap: { enabled: 'allow_is_free' },
          item: { enabled: false }
        },
        purchase_price: {
          enabled: false,
          propsMap: { purchase_price: 'purchase_price' },
          item: null
        },
        amount_gross: {
          enabled: false,
          propsMap: { amount_gross: 'amount_gross' },
          item: null
        },
        locations: {
          enabled: false,
          propsMap: { values: 'locations' },
          item: null
        }
      },
      productsFetched: [],
      selectedItemsWithVariants: []
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    productsToChange() {
      if (this.allSelected) {
        return this.productsFetched
      } else if (this.isSuplliersChecked) {
        return this.selectedItemsWithVariants
      } else {
        return this.selectedItems
      }
    },
    isSuplliersChecked() {
      return this.fields.suppliers.enabled
    },
    selectedSuppliers() {
      return this.fields.suppliers.item
    },
    anyFieldEnabled() {
      return Object.values(this.fields).find((f) => !!f.enabled)
    },
    resourceQuery() {
      return {
        deleted: false,
        extended: true,
        location: this.currentLocation || undefined,
        limit: 100
      }
    },
    i18nParams() {
      return {
        productsSize: this.productsToChange.length,
        productsWord:
          this.productsToChange.length === 1
            ? this.$t('common.resource.product.singular')
            : this.$t('common.resource.product.plural')
      }
    },
    newFieldsProducts() {
      return this.productsToChange.map((product) => {
        return {
          id: product.id,
          ...this.enabledFields()
        }
      })
    },
    resourceList() {
      const resources = [
        'productGroups',
        'taxes',
        'tags',
        'branchGroups',
        'branchesV1',
        'suppliers',
        {
          resource: 'accounts',
          handler: () =>
            th.accounts().getAll({ type: 'revenue', deleted: false })
        }
      ]
      return resources
    },
    productsList() {
      return this.productsToChange.map((product) => ({
        ...product,
        suppliers: this.fields.suppliers.item
      }))
    },
    isExtraVariantsShown() {
      if (!this.isSuplliersChecked || this.allSelected) {
        return false
      }
      return this.selectedItems.length !== this.selectedItemsWithVariants.length
    }
  },

  async created() {
    await this.fetchResources()

    if (this.allSelected) {
      await this.fetchProducts()
    } else if (this.selectedItems.length) {
      await this.fetchProductVariants()
    }
  },

  methods: {
    async fetchResources() {
      const {
        branchesV1 = [],
        branchGroups = [],
        productGroups = [],
        taxes = [],
        tags = [],
        accounts = [],
        suppliers = []
      } = await this.$resourceFetch(...this.resourceList)
      this.resources = {
        locations: branchesV1,
        branchGroups,
        product_groups: productGroups,
        taxes,
        tags,
        accounts,
        suppliers
      }
    },
    handleCancel() {
      this.$router.push({ name: 'products-manager' })
      this.productsStore.clearSelectedItems()
    },
    handleBack() {
      if (this.step === 2) this.step--
    },
    handleNext() {
      this.validate('fieldsChangeForm', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.message.invalid_inputs')
          })
        }

        if (this.step === 1) this.step++
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate(cb)
    },
    async fetchProducts() {
      const inst = th.products()
      const action = this.filters.q
        ? inst.search(this.filters.q)
        : inst.getAll({ query: { ...this.resourceQuery, ...this.filters } })

      try {
        this.loading = true
        const { data, next } = await action
        if (data.starts_with || data.length) {
          this.productsFetched =
            typeOf(data) === 'object' && data.starts_with
              ? data.starts_with
              : data
        }
        this.next = next || null
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    async fetchProductVariants() {
      try {
        this.loading = true

        const allVariantProducts = this.selectedItems.filter(
          (item) => item.type === 'variant_product'
        )
        const allVariants = this.selectedItems.filter(
          (item) => item.type === 'variant'
        )
        const parentlessVariantIds = allVariants
          .filter(
            (item) => !allVariantProducts.find(({ id }) => id === item.parent)
          )
          .map(({ id }) => id)

        const promises = this.selectedItems
          // We want to remove variants from that list to avoid duplication, because we will fetch
          // all the variants by parents on the next step, but want to remove only those
          // with no parent inside the selection because otherwise they will just disappear from the list
          .filter((item) => {
            if (
              item.type === 'variant' &&
              !parentlessVariantIds.includes(item.id)
            ) {
              return false
            }
            return true
          })
          .map((product) => {
            if (product.type === 'variant_product') {
              return th
                .products()
                .getChildrenDetails(product.id, true)
                .then(({ data }) => [product, ...data])
            }
            // We're adding regular products to that promises chain to keep products order
            // We want product variants to be added right after their parents
            return Promise.resolve([product])
          })

        const products = await Promise.all(promises)

        this.selectedItemsWithVariants = flatten(products)
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    paginate: debounce(
      async function () {
        if (this.next) {
          try {
            this.loading = true
            const { data, next } = await this.next()
            this.productsFetched = this.productsFetched.concat(data)
            this.next = next || null
          } catch (err) {
            this.$logException(err, { trackError: false })
          } finally {
            this.loading = false
          }
        }
      },
      500,
      true
    ),
    async bulkEditProducts() {
      const successMessage = this.$t('common.success.action.update.multiple', {
        resources: this.$t('common.resource.product.plural')
      })
      const errorMessage = this.$t('common.error.action.update.multiple', {
        resources: this.$t('common.resource.product.plural')
      })

      this.pageLoading = true
      try {
        await th.products().bulkEdit(this.newFieldsProducts)
        // Bulk save supplier
        if (this.selectedSuppliers) {
          const productId = this.newFieldsProducts.map(({ id }) => id)
          const suppliersProductsRelation = th.suppliersProductsRelation()
          await Promise.all(
            this.selectedSuppliers.map((supplier) =>
              suppliersProductsRelation.bulkCreate(supplier, {
                productId
              })
            )
          )
        }
        this.$router.push({ name: 'products-manager' })
        this.$message({
          type: 'success',
          message: successMessage
        })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.pageLoading = false
      }
    },
    enabledFields() {
      let parsedFields = { ...this.fields }
      //product attributes that will be updated
      delete parsedFields.suppliers

      parsedFields = constructFields(parsedFields)
      parsedFields = this.setConfiguration(parsedFields)
      parsedFields = this.setPrices(parsedFields)
      parsedFields = this.setLocations(parsedFields)
      return parsedFields
    },

    setConfiguration(product) {
      // Avoid changing the object if not necessary
      if (!('request_input' in product) && !('allow_is_free' in product))
        return product

      product.configuration = { pricing: {} }

      if ('request_input' in product) {
        product.configuration.pricing.request_input = product.request_input
        delete product.request_input
      }
      if ('allow_is_free' in product) {
        product.configuration.pricing.zero_input_allowed = !!product.allow_is_free
        // Dashboard always set it to false
        product.configuration.pricing.allow_is_free = false
        delete product.allow_is_free
      }

      return product
    },

    setPrices(product) {
      // Avoid changing the object if not necessary
      if (!('purchase_price' in product) && !('amount_gross' in product))
        return product

      product.prices = { default_prices: [{ amount: {} }] }

      if ('purchase_price' in product) {
        product.prices.default_prices[0].purchase_price = product.purchase_price
        delete product.purchase_price
      }
      if ('amount_gross' in product) {
        product.prices.default_prices[0].amount.gross = product.amount_gross
        delete product.amount_gross
      }
      if (!Object.keys(product.prices.default_prices[0].amount).length)
        delete product.prices.default_prices[0].amount

      return product
    },

    setLocations(product) {
      // Avoid changing the object if not necessary
      if (!('locations' in product)) return product

      product.branch_groups = product.locations.branch_groups
      product.locations = product.locations.locations

      return product
    }
  }
}
</script>

<style scoped>
.staff-edit :deep(.el-main) {
  overflow: auto;
}
</style>
