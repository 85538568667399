<template>
  <div>
    <el-form ref="form" :rules="rules" :model="modelValue">
      <div class="my-8 mx-10 lg:mx-32">
        <el-row :gutter="80">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <!-- Product Groups -->
            <el-form-item prop="product_group.item.id">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.product_group.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'product_group')"
                  >
                    {{
                      $t(
                        'pages.products.edit.form.properties.product_group.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>

                <el-col :span="11">
                  <th-resource-select
                    data-testid="product-group-select"
                    class="w-full"
                    :disabled="!modelValue.product_group.enabled"
                    :resource-query="{ query: { deleted: false } }"
                    :options="productGroupsOptions"
                    :computed-fields="['product_group_id', 'name']"
                    resource="productGroups"
                    :model-value="
                      (modelValue.product_group.item &&
                        modelValue.product_group.item.id) ||
                      null
                    "
                    load-lazy
                    @resource-set="setField($event, 'item', 'product_group')"
                  />
                </el-col>
                <el-col :span="2">
                  <th-popover
                    class="pl-4"
                    :text="
                      $t(
                        'pages.products.bulk_edit.product_group.popover.message'
                      )
                    "
                    placement="bottom-start"
                  />
                </el-col>
              </el-row>
            </el-form-item>

            <!-- Tags -->
            <el-form-item prop="tags.item.id">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.tags.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'tags')"
                  >
                    {{ $t('common.headers.tags.title') }}
                  </el-checkbox>
                </el-col>

                <el-col :span="11">
                  <el-select
                    v-cancel-read-only
                    class="w-full"
                    value-key="id"
                    filterable
                    clearable
                    multiple
                    default-first-option
                    :disabled="!modelValue.tags.enabled"
                    :model-value="value.tags.item"
                    @change="setField($event, 'item', 'tags')"
                  >
                    <el-option
                      v-for="tag of resources.tags"
                      :key="tag.id"
                      :label="tag.name"
                      :value="tag"
                    />
                  </el-select>
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Suppliers -->
            <el-form-item prop="suppliers.item.id">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.suppliers.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'suppliers')"
                  >
                    {{ $t('common.resource.supplier.plural') }}
                  </el-checkbox>
                </el-col>

                <el-col :span="11">
                  <product-suppliers
                    id="suppliers"
                    :disabled="!value.suppliers.enabled"
                    :resources="resources"
                    :model-value="value.suppliers.item"
                    @update:modelValue="setField($event, 'item', 'suppliers')"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <hr class="border-solid border border-gray-200 my-4" />

            <!-- Tax -->
            <el-form-item prop="tax.item.id">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.tax.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'tax')"
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.vat_account.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <th-resource-select
                    class="w-full"
                    :disabled="!modelValue.tax.enabled"
                    :resource-query="{ query: { deleted: false } }"
                    :options="resources.taxes"
                    :computed-fields="['name']"
                    resource="taxes"
                    :model-value="
                      (modelValue.tax.item && modelValue.tax.item.id) || null
                    "
                    load-lazy
                    @resource-set="setField($event, 'item', 'tax')"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Takeaway Tax -->
            <el-form-item v-if="isTakeawayEnabled" prop="takeaway_tax.item.id">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.takeaway_tax.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'takeaway_tax')"
                  >
                    {{
                      $t(
                        'pages.product_groups.edit.form.properties.takeaway.tax.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <th-resource-select
                    class="w-full"
                    :disabled="!modelValue.takeaway_tax.enabled"
                    :resource-query="{ query: { deleted: false } }"
                    :options="resources.taxes"
                    :computed-fields="['name']"
                    resource="taxes"
                    :model-value="
                      (modelValue.takeaway_tax.item &&
                        modelValue.takeaway_tax.item.id) ||
                      null
                    "
                    load-lazy
                    @resource-set="setField($event, 'item', 'takeaway_tax')"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Revenue Account -->
            <el-form-item prop="account.item.id">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.account.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'account')"
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.revenue_account.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <th-resource-select
                    class="w-full"
                    :disabled="!modelValue.account.enabled"
                    :resource-query="{ query: { deleted: false } }"
                    :options="resources.accounts"
                    :computed-fields="['name']"
                    resource="accounts"
                    :model-value="
                      (modelValue.account.item && modelValue.account.item.id) ||
                      null
                    "
                    load-lazy
                    @resource-set="setField($event, 'item', 'account')"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Takeaway Revenue Account -->
            <el-form-item
              v-if="isTakeawayEnabled"
              prop="takeaway_account.item.id"
            >
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.takeaway_account.enabled"
                    class="pr-8"
                    @change="setField($event, 'enabled', 'takeaway_account')"
                  >
                    {{
                      $t(
                        'pages.product_groups.edit.form.properties.takeaway.account.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <th-resource-select
                    class="w-full"
                    :disabled="!modelValue.takeaway_account.enabled"
                    :resource-query="{ query: { deleted: false } }"
                    :options="resources.accounts"
                    :computed-fields="['name']"
                    resource="accounts"
                    :model-value="
                      (modelValue.takeaway_account.item &&
                        modelValue.takeaway_account.item.id) ||
                      null
                    "
                    load-lazy
                    @resource-set="setField($event, 'item', 'takeaway_account')"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Purchase price -->
            <el-form-item prop="purchase_price">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.purchase_price.enabled"
                    class="pr-8"
                    @update:modelValue="
                      setField($event, 'enabled', 'purchase_price')
                    "
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.purchase_price.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <th-currency-input
                    id="purchase_price"
                    v-model="purchase_price"
                    :disabled="!value.purchase_price.enabled"
                    :currency="defaultCurrency"
                    :locale="$i18n.locale"
                    clearable-is-left
                    size="large"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Gross price -->
            <el-form-item prop="amount_gross">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.amount_gross.enabled"
                    class="pr-8"
                    @update:modelValue="
                      setField($event, 'enabled', 'amount_gross')
                    "
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.amount_gross.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <th-currency-input
                    v-model="amount_gross"
                    :disabled="!value.amount_gross.enabled"
                    :currency="defaultCurrency"
                    :locale="$i18n.locale"
                    clearable-is-left
                    size="large"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <!-- Discountable -->
            <el-form-item prop="discountable">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.discountable.enabled"
                    class="pr-8"
                    @update:modelValue="
                      setField($event, 'enabled', 'discountable')
                    "
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.discountable.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <el-switch
                    id="discountable"
                    v-model="discountable"
                    :disabled="!value.discountable.enabled"
                    data-testid="discountable-bulk"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Request input -->
            <el-form-item prop="request_input">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.request_input.enabled"
                    class="pr-8"
                    @update:modelValue="
                      setField($event, 'enabled', 'request_input')
                    "
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.request_input.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <el-switch
                    id="request_input"
                    v-model="request_input"
                    :disabled="!value.request_input.enabled"
                    data-testid="request-input-bulk"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Allow is free -->
            <el-form-item prop="allow_is_free">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.allow_is_free.enabled"
                    class="pr-8"
                    @update:modelValue="
                      setField($event, 'enabled', 'allow_is_free')
                    "
                  >
                    {{
                      $t(
                        'pages.products.bulk_edit.properties.allow_is_free.label'
                      )
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <el-switch
                    id="allow_is_free"
                    v-model="allow_is_free"
                    :disabled="!value.allow_is_free.enabled"
                    data-testid="allow-is-free-bulk"
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>

            <!-- Locations -->
            <el-form-item prop="locations">
              <el-row type="flex" justify="space-between" :gutter="0">
                <el-col :span="11">
                  <el-checkbox
                    :model-value="value.locations.enabled"
                    class="pr-8"
                    @update:modelValue="
                      setField($event, 'enabled', 'locations')
                    "
                  >
                    {{
                      $t('pages.products.bulk_edit.properties.locations.label')
                    }}
                  </el-checkbox>
                </el-col>
                <el-col :span="11">
                  <available-in
                    id="locations"
                    :model-value="{
                      locations: locations,
                      branch_groups: branch_groups
                    }"
                    :disabled="!value.locations.enabled"
                    :resources="resources"
                    :show-items-limit="2"
                    @update:modelValue="
                      setField({ values: $event }, 'item', 'locations')
                    "
                  />
                </el-col>
                <el-col :span="2" />
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <!-- Collision warnings -->
    <transition-group name="fade">
      <div
        v-if="isFieldsCollision"
        key="isFieldsCollision"
        class="flex justify-center"
      >
        <div
          class="my-4 bg-red-200 px-5 py-4 text-red-700 rounded text-sm lg:max-w-6/12 sm:w-9/12 flex items-center max-w-xl"
        >
          <el-icon class="mr-4 text-xl"><Warning /></el-icon>
          <div>
            <p
              v-text="
                $t(
                  'pages.products.bulk_edit.select_fields.collision_warning.first.text'
                )
              "
            />
            <p
              v-text="
                $t(
                  'pages.products.bulk_edit.select_fields.collision_warning.second.text'
                )
              "
            />
          </div>
        </div>
      </div>
      <div
        v-if="shouldWarnNoProductGroup"
        key="shouldWarnNoProductGroup"
        class="flex justify-center"
      >
        <div
          class="my-4 bg-red-200 px-5 py-4 text-red-700 rounded text-sm lg:max-w-6/12 sm:w-9/12 flex items-center max-w-xl"
        >
          <el-icon class="mr-4 text-xl"><Warning /></el-icon>
          <div>
            <p
              v-text="
                $t('pages.products.bulk_edit.select_fields.strict_mode.text')
              "
            />
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import get from 'just-safe-get'
import AvailableIn from '@/components/available-in'
import ProductSuppliers from '@/views/products/v2/components/additional-information/product-suppliers'

export default {
  components: {
    AvailableIn,
    ProductSuppliers
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    i18nParams: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      rules: {
        'product_group.item.id': [
          {
            validator: (...args) =>
              this.validateField('product_group', ...args),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        'tax.item.id': [
          {
            validator: (...args) => this.validateField('tax', ...args),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        'account.item.id': [
          {
            validator: (...args) => this.validateField('account', ...args),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        'takeaway_tax.item.id': [
          {
            validator: (...args) => this.validateField('takeaway_tax', ...args),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        'takeaway_account.item.id': [
          {
            validator: (...args) =>
              this.validateField('takeaway_account', ...args),
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    discountable: {
      get() {
        return this.modelValue?.discountable?.item?.enabled
      },
      set(value) {
        this.setField({ enabled: value }, 'item', 'discountable')
      }
    },
    request_input: {
      get() {
        return this.modelValue?.request_input?.item?.enabled
      },
      set(value) {
        this.setField({ enabled: value }, 'item', 'request_input')
      }
    },
    allow_is_free: {
      get() {
        return this.modelValue?.allow_is_free?.item?.enabled
      },
      set(value) {
        this.setField({ enabled: value }, 'item', 'allow_is_free')
      }
    },
    purchase_price: {
      get() {
        return this.modelValue?.purchase_price?.item?.purchase_price
      },
      set(value) {
        this.setField({ purchase_price: value }, 'item', 'purchase_price')
      }
    },
    amount_gross: {
      get() {
        return this.modelValue?.amount_gross?.item?.amount_gross
      },
      set(value) {
        this.setField({ amount_gross: value }, 'item', 'amount_gross')
      }
    },
    suppliers: {
      get() {
        return this.modelValue?.suppliers?.item
      },
      set(value) {
        this.setField(value, 'item', 'suppliers')
      }
    },
    locations() {
      return this.modelValue?.locations?.item?.values.locations
    },
    branch_groups() {
      return this.modelValue?.locations?.item?.values.branch_groups
    },
    defaultCurrency() {
      return this.$store.getters['Config/getCurrentDefaultCurrency']
    },
    isFieldsCollision() {
      const productGroupField = get(this.modelValue, 'product_group.enabled')
      const taxField = get(this.modelValue, 'tax.enabled')
      const revenueAccountField = get(this.modelValue, 'account.enabled')
      const takeawayTaxField = get(this.modelValue, 'takeaway_tax.enabled')
      const takeawayAccountField = get(
        this.modelValue,
        'takeaway_account.enabled'
      )
      // because changing product group will already lead to a change of vat and revenue that been set on the group,
      // we're not allowing to change all the fields.
      return (
        productGroupField &&
        (taxField ||
          revenueAccountField ||
          takeawayTaxField ||
          takeawayAccountField)
      )
    },
    productGroupsOptions() {
      return [
        {
          id: 'null',
          name: this.$t('pages.products.bulk_edit.product_groups.select.none')
        },
        ...(this.resources.product_groups || [])
      ]
    },
    isStrictMode() {
      return get(
        this.$store.state.Config.clientAccountConfiguration,
        'products.strict_mode'
      )
    },
    shouldWarnNoProductGroup() {
      return (
        this.isStrictMode &&
        get(this.modelValue, 'product_group.enabled') &&
        get(this.modelValue, 'product_group.item.id') === 'null'
      )
    },
    isTakeawayEnabled() {
      return this.$isFeatureEnabled('takeaway')
    }
  },

  methods: {
    setField(value, prop, field) {
      const fieldData = this.modelValue[field]
      if (!field || !fieldData) return
      this.$emit('update:modelValue', {
        ...this.modelValue,
        [field]: { ...fieldData, [prop]: value }
      })
    },
    validateField(field, rule, value, callback) {
      if (
        this.isStrictMode &&
        field === 'product_group' &&
        this.modelValue[field].enabled &&
        get(this.modelValue, `${field}.item.id`) === 'null'
      ) {
        return callback(new Error())
      }

      if (
        this.modelValue[field] &&
        this.modelValue[field].enabled &&
        !this.modelValue[field].item
      ) {
        // on field collision we prefer not to show the field validation error
        return this.isFieldsCollision ? callback() : callback(new Error())
      }

      callback()
    },
    validate(cb) {
      this.$refs.form.validate((valid) => {
        const isValid = valid && !this.isFieldsCollision
        return cb(isValid)
      })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
