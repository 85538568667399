<template>
  <div class="products-list h-full min-h-0 flex flex-col">
    <div class="h-full flex flex-col px-8 pb-4">
      <div class="h-full">
        <th-table
          class="bg-white rounded-th-normal"
          :columns="columns"
          :data="tableData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import mem from 'mem'
import { camelToSnakeCase } from '@/utils/strings'
import ThTable from '@/components/table'

export default {
  components: {
    ThTable
  },

  props: {
    productsToChange: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    i18nParams: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    translations() {
      return {
        voucher: this.$t('common.products.types.voucher.title'),
        variant_product: this.$t('common.products.types.variant_product.title'),
        variant: this.$t('common.products.types.variant.title'),
        product: this.$t('common.products.types.product.title'),
        linked_product: this.$t('common.products.types.linked_product.title'),
        linked: this.$t('common.products.types.linked.title'),
        composed_product: this.$t(
          'common.products.types.composed_product.title'
        )
      }
    },
    columns() {
      const takeawayTaxHeader = this.isTakeawayEnabled
        ? [
            {
              key: '_takeaway_tax_label',
              label: this.$t(
                'pages.product_groups.edit.form.properties.takeaway.tax.label'
              ),
              visible: true
            }
          ]
        : []
      const takeawayAccountHeader = this.isTakeawayEnabled
        ? [
            {
              key: '_takeaway_account_label',
              label: this.$t(
                'pages.product_groups.edit.form.properties.takeaway.account.label'
              ),
              visible: true
            }
          ]
        : []

      // TODO: for table big data optimization handle formatters when data is being fetched
      return [
        {
          key: 'name',
          label: this.$t('pages.products.all.headers.name'),
          visible: true
        },
        {
          key: 'custom_id',
          label: this.$t('pages.products.all.headers.custom_id'),
          visible: true
        },
        {
          key: 'type',
          label: this.$t('pages.products.all.headers.type'),
          formatter: (item) => this.translations[item.type] || '–',
          visible: true
        },
        {
          key: '_product_group_label',
          label: this.$t('pages.products.all.headers.product_group'),
          visible: true
        },
        {
          key: '_tags_label',
          label: this.$t('common.headers.tags.title'),
          visible: true
        },
        {
          key: '_suppliers_label',
          label: this.$t('common.resource.supplier.plural'),
          formatter: (item) => {
            const supplierNameList = item.suppliers.map(
              (id) => this.getItemDetails(id, 'suppliers') || id
            )
            return supplierNameList.length ? supplierNameList.join(', ') : '–'
          },
          visible: true
        },
        {
          key: '_tax_label',
          label: this.$t(
            'pages.products.bulk_edit.properties.vat_account.label'
          ),
          visible: true
        },
        ...takeawayTaxHeader,
        {
          key: '_account_label',
          label: this.$t(
            'pages.products.bulk_edit.properties.revenue_account.label'
          ),
          visible: true
        },
        ...takeawayAccountHeader,
        {
          key: 'stock_info',
          label: this.$t('pages.products.all.headers.stock_info'),
          formatter: (item) => {
            if (this.currentLocation) {
              const stockLocations =
                safeGet(item.stock_info, 'all.locations') || []
              const currentLocationStock =
                stockLocations.find(
                  (location) => location.id === this.currentLocation
                ) || {}
              return Number.isFinite(currentLocationStock.qty)
                ? `${currentLocationStock.qty}`
                : '–'
            } else if (
              item.type === 'variant_product' &&
              Number.isFinite(safeGet(item.stock_info, 'all.total.qty'))
            ) {
              // we have collected cumulated stock for variant products
              return `${safeGet(item.stock_info, 'all.total.qty')}`
            } else if (
              Number.isFinite(safeGet(item.stock_info, 'summary.total.qty'))
            ) {
              return `${safeGet(item.stock_info, 'summary.total.qty')}`
            }

            return '–'
          }
        },
        {
          key: 'prices',
          label: this.$t('pages.products.all.headers.price'),
          formatter: (item) => {
            if (
              item.prices &&
              item.prices.default_prices &&
              Array.isArray(item.prices.default_prices)
            ) {
              const currentCurrencyPrice = item.prices.default_prices.find(
                (price) => price.currency === 'EUR'
              )

              if (
                !currentCurrencyPrice ||
                !currentCurrencyPrice.amount ||
                !Number.isFinite(currentCurrencyPrice.amount.gross)
              ) {
                return '–'
              } else {
                return (
                  this.$formatCurrency(
                    currentCurrencyPrice.amount.gross,
                    'EUR'
                  ) || '–'
                )
              }
            }
            return '–'
          }
        }
      ]
    },
    labels() {
      return Object.keys(this.fields).reduce((acc, field) => {
        acc[`_${camelToSnakeCase(field)}_label`] = field
        return acc
      }, {})
    },
    tableData() {
      return this.productsToChange.map((p) => ({
        ...p,
        _product_group_label:
          this.fieldValueName.product_group ||
          this.getItemDetails(p.product_group, 'product_groups'),
        _tags_label:
          this.fieldValueName.tags ||
          p.tags?.map((tag) => this.getItemDetails(tag, 'tags')).join(', '),
        _supplier_label:
          this.fieldValueName.supplier ||
          this.getItemDetails(this.fields?.supplier?.item, 'suppliers'),
        _tax_label:
          this.fieldValueName.tax || this.getItemDetails(p.tax, 'taxes'),
        _account_label:
          this.fieldValueName.account ||
          this.getItemDetails(p.account, 'accounts'),
        _takeaway_tax_label:
          this.fieldValueName.takeaway_tax ||
          this.getItemDetails(p.takeaway_tax, 'taxes'),
        _takeaway_account_label:
          this.fieldValueName.takeaway_account ||
          this.getItemDetails(p.takeaway_account, 'accounts')
      }))
    },
    fieldValueName() {
      const getItem = (field) => {
        const opts = safeGet(this.fields, field)
        const item = opts.enabled ? opts.item : undefined
        return Array.isArray(item)
          ? item.map(({ name }) => name).join(', ')
          : item?.name
      }
      return Object.keys(this.fields).reduce((acc, field) => {
        acc[field] = getItem(field)
        return acc
      }, {})
    },
    isTakeawayEnabled() {
      return this.$isFeatureEnabled('takeaway')
    }
  },

  methods: {
    cellClassName({ column }) {
      const field = this.labels[column.property]
      if (field && this.fieldValueName[field]) {
        return 'bg-gray-100'
      }
    },
    onScroll(val) {
      const { scrollTop, offsetHeight, scrollHeight } =
        safeGet(val, '$event.target') || {}

      if (scrollTop + offsetHeight >= scrollHeight) {
        this.$emit('paginate')
      }
    },
    // for perf we memoizing the function to prevent too many computations when rendering the table
    getItemDetails: mem(
      function (id, resource) {
        if (!id || !resource || !this.resources[resource]) return '–'

        switch (resource) {
          case 'product_groups':
            return (
              this.resources.product_groups.find((group) => group.id === id) ||
              {}
            ).name
          case 'tags':
            return (this.resources.tags.find((tag) => tag.id === id) || {}).name
          case 'suppliers':
            return (
              this.resources.suppliers.find((supplier) => supplier.id === id) ||
              {}
            ).companyName
          case 'taxes':
            return (this.resources.taxes.find((tax) => tax.id === id) || {})
              .name
          case 'accounts': {
            const account =
              this.resources.accounts.find((account) => account.id === id) || {}
            return account.name
          }
          default:
            return undefined
        }
      },
      {
        cacheKey: (args) => args.join(',')
      }
    )
  }
}
</script>
